<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
       <v-toolbar  dark color="secondary">
              <v-icon left @click="emitToParent('cancel')" v-if="ShowClear">mdi-arrow-left</v-icon> You are now playing {{GAMENAME(gamename)}} - {{ pick }}
            </v-toolbar>
       <v-card-text class="text-center">
         <div class="pt-5">
                  <v-text-field
                        v-model="bettor"
                        label="Bettor's Name"
                        required
                        @change="set_bettor()"
                        outlined
                          hide-details
                        dense
                      ></v-text-field>
         </div>

         
       </v-card-text>
       <div class="text-center" v-if="valid_bettor">
          
            <v-divider class="mb-2"/>
            <div>
               <!-- <v-btn tile @click="pick='P2', clear()" :color="pick=='P2'?'primary':''">P2</v-btn>
               <v-btn tile @click="pick='P3', clear()" :color="pick=='P3'?'primary':''">P3</v-btn>
               <v-btn tile @click="pick='P4', clear()" :color="pick=='P4'?'primary':''">P4</v-btn>
               <v-btn tile @click="pick='P5', clear()" :color="pick=='P5'?'primary':''">P5</v-btn>
               <v-btn tile @click="pick='P6', clear()" :color="pick=='P6'?'primary':''">P6</v-btn> -->
             
              </div>    
         </div>
      
       <template v-if="valid_bettor">
            <v-toolbar>
              <template v-if="pick =='P2'">
              <v-spacer/> <div class="text-h4 font-weight-bold"> {{num1}} - {{num2}}</div> <v-spacer/> 
            </template> 
            <template v-if="pick =='P2-SAKLANG'">
              <v-spacer/> <div class="text-h4 font-weight-bold"> {{num1}} - {{num2}}</div> <v-spacer/> 
            </template> 
            <template v-if="pick =='P2-POMPYANG'">
              <v-spacer/> <div class="text-h4 font-weight-bold"> {{num1}} - {{num2}}</div> <v-spacer/> 
            </template> 
            <template v-if="pick =='P3'">
              <v-spacer/> <div class="text-h4 font-weight-bold">{{num1}} - {{num2}} - {{num3}}</div> <v-spacer/> 
            </template> 
            <template v-if="pick =='P4'">
              <v-spacer/> <div class="text-h4 font-weight-bold">{{num1}} - {{num2}} - {{num3}} - {{num4}}</div> <v-spacer/> 
            </template> 
            <template v-if="pick =='P5'">
              <v-spacer/> <div class="text-h4 font-weight-bold">{{num1}} - {{num2}} - {{num3}} - {{num4}} - {{num5}}</div> <v-spacer/> 
            </template> 
            <template v-if="pick =='P6'">
              <v-spacer/> <div class="text-h4 font-weight-bold">{{num1}} - {{num2}} - {{num3}} - {{num4}} - {{num6}}</div> <v-spacer/> 
            </template> 
               <v-btn  x-small color="warning" fab @click="clear()"  v-if="ShowClear"><v-icon >mdi-close</v-icon></v-btn>
            </v-toolbar>
     
        <v-card-text class="text-center mt-4">
          <v-btn fab  color="white" small class="lg-font ma-1" v-for="num in nums" :key="num"  @click="setNum(num)" >{{num}}</v-btn>
          <v-btn fab  color="success" small class="lg-font ma-1"  @click="genComb()" >LP</v-btn>
          <v-divider  class="mt-4" />
        </v-card-text>
        <v-row class="ma-2" dense>
          <!-- <v-col
                cols="12"
             
                >
                <v-btn block tile @click="bet_type='STD'"  :color="bet_type=='STD'?'primary':''">STANDARD</v-btn>
              </v-col>
            <v-col
                cols="6"
                >
                <v-btn block tile @click="bet_type='RBL'"  :color="bet_type=='RBL'?'primary':''">RAMBOL</v-btn>
              </v-col>
                <v-col
                cols="6"
                >
                <v-btn block tile @click="bet_type='CASAS'" :color="bet_type=='CASAS'?'primary':''">CASAS</v-btn>
              </v-col> -->
              <v-col
                cols="12"
                >
                <v-divider  class="mt-4 mb-4" />
              </v-col>
              
            <template v-if="bet_type=='CASAS'">
                <v-col
                cols="6"
                class="text-center"
                >
                   <span class="text-secondary">Tombok Bet</span>
                     <input type="number" v-model="tombok" @focus="tombok=''" class="amountbox"/>
                </v-col>
                <v-col
                cols="6"
                class="text-center"
                >
                <span class="text-secondary">Sahud Bet</span>
                <input type="number" label="Sahud Bet" v-model="sahud" @focus="sahud=''" class="amountbox"/>
                </v-col>
              </template>

              <template v-if="bet_type=='RBL'">
                <v-col
                cols="12"
                class="text-center"
                >
                   <span class="text-secondary">Bet Amount</span>
                     <input type="number" v-model="rambol" @focus="rambol=''" class="amountbox"/>
                </v-col>
               
              </template>
              <template v-if="bet_type=='STD'">
                <v-col
                cols="12"
                class="text-center"
                >
                   <span class="text-secondary">Bet Amount</span>
                     <input type="number" v-model="standard" @focus="standard=''" class="amountbox"/>
                </v-col>
               
              </template>

                <v-col
                cols="12"
               
                >
                <v-divider  class="mt-4 mb-4" />
              </v-col>
                
         
       
           
        </v-row>
       </template>

       <v-card-actions  v-if="tombok>0 || rambol>0 || standard>0">
        <v-btn  large color="warning"   @click="emitToParent('cancel')"><v-icon left>mdi-close</v-icon>CANCEL</v-btn>
        <v-spacer />
        <v-btn large color="primary"    @click="validateNums()"><v-icon left>mdi-check</v-icon>Place bet</v-btn>
       </v-card-actions>
       
    </v-card>
    <va-amount-form :show="amountDialog" :numarr="ARR_NUM_DISPLAY" :linetype="bet_type" @DialogEvent="amountEvent"/>
  </v-dialog>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
      num1: '_',
      num2: '_',
      num3: '_',
      num4: '_',
      num5: '_',
      num6: '_',
      pick:'',
      tombok:0,
      sahud:0,
      rambol:0,
      standard: 0,
      amountDialog: false,
      line: {},
      bet_type:'', 
      bettor: '',
      NoRepetetion:["P3","P4","P5","P6"]
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    nums: {
       type: Number,
        default: 9
    },
    gamename: {
       type: String,
        default: '2D'
    }
  },
  watch:{
    show(v){
      if(v) {
        this.clear()
        this.tombok = 0
        this.sahud = 0
        this.rambol = 0
        this.standard = 0
        this.bet_type =""
        this.bettor = this.$store.state.bettor_name
        this.pick = this.$store.state.pick
        if(this.pick == 'P2') {
          this.bet_type ="CASAS"
        } else if(this.pick == 'P2-SAKLANG' || this.pick == 'P3') {
          this.bet_type ="RBL"
        } else if(this.pick =='P2-POMPYANG'){
           this.bet_type ="STD"
        }
      } 
    }
  },
  computed: {
    ShowClear() {
      if(this.num1 || this.num2) {
        return true
      } 
      return false
    },
     ARR_NUM_DISPLAY() {
      if(this.pick == "P3") {
           return [this.num1,this.num2, this.num3]
      } else if(this.pick == "P4") {
           return [this.num1,this.num2, this.num3, this.num4]
      } else if(this.pick == "P5") {
           return [this.num1,this.num2, this.num3, this.num4, this.num5]
      }  else  {
        return [this.num1,this.num2, this.num3, this.num4, this.num5,  this.num6]
      }
    },
    valid_bettor(){
      if(this.bettor.length> 2) {
        return true
      }
      return false
    }
  },
  methods: {
      ...mapMutations(['setBettorName']),
    set_bettor(){
     
      this.setBettorName(this.bettor.trim())
      console.log("set bettor", this.$store.state.bettor_name)
    },
    isNoRepet(pick) {
      var arr = this.NoRepetetion
      for (let i = 0; i < arr.length; i++) {
          if(pick == arr[i]) {
            return true
          }
      }
       return false
    },
    genComb() {
        this.num1 = this.getRand(1,38)
        this.num2 =this.getRand(1,38)
        this.num3 =this.getRand(1,38)
        this.num4 =this.getRand(1,38)
        this.num5 =this.getRand(1,38)
        this.num6 =this.getRand(1,38)
        if(this.pick =='P2-POMPYANG') {
          this.num2 = this.num1
        }
    },
    getRand(min, max){
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    amountEvent(e){
      this.amountDialog = false
      if(e != 'close') {
        this.returnLine(e)
      }
    },
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
      this.num1 = '_'
      this.num2 = '_'
      this.num3 = '_'
      this.num4 = '_'
      this.num5 = '_'
      this.num6 = '_'
        this.line = {}
        console.log("check bettor", this.$store.state.bettor_name)
    },
    calAmount(){
      if(this.bet_type == "RBL") {
        return this.$IsNum(this.rambol)
      }
      if(this.bet_type == "STD") {
        return this.$IsNum(this.standard)
      }
      let _tombok = 0
      let _sahud = 0
      if(this.pick=='P3') {
        _tombok = this.$IsNum(this.tombok) * 3
        _sahud = this.$IsNum(this.sahud) * 3
      } else if(this.pick == "P4") {
        _tombok = this.$IsNum(this.tombok) * 6
        _sahud = this.$IsNum(this.sahud) * 6
      } else if(this.pick == "P5") {
        _tombok = this.$IsNum(this.tombok) * 10
        _sahud = this.$IsNum(this.sahud) * 10
      } else if(this.pick == "P6") {
        _tombok = this.$IsNum(this.tombok) * 15
        _sahud = this.$IsNum(this.sahud) * 15
      } else {
        _tombok = this.$IsNum(this.tombok) 
        _sahud = this.$IsNum(this.sahud)
      }
      return _tombok + _sahud
    },
    returnLine() {
      //6,12.20.30
      this.line.tombok = this.tombok.toString()
      this.line.sahud = this.sahud.toString()
      this.line.total_amount =  this.calAmount().toString()
      this.line.line_amount = this.calAmount().toString()
      this.line.line_type = this.bet_type+"-"+ this.pick
      this.line.num1 = this.num1
      this.line.num2 = this.num2
      if(this.pick=='P3') {
        this.line.num3 = this.num3
      }
      if(this.pick == "P4") {
        this.line.num3 = this.num3
         this.line.num4 = this.num4
      }
      if(this.pick == "P5") {
        this.line.num3 = this.num3
        this.line.num4 = this.num4
         this.line.num5 = this.num5
      }
      if(this.pick == "P6") {
        this.line.num3 = this.num3
        this.line.num4 = this.num4
         this.line.num5 = this.num5
         this.line.num6 = this.num6
      }
      console.log(this.line)
      if(this.calAmount()>0){
         this.emitToParent({line:this.line, bettor:this.bettor})
      }
     
    },
    hasDuplicate() {
      var arry = this.ARR_NUM_DISPLAY
      for (let i = 0; i < arry.length; i++) {
          for (let c = 0; c < arry.length; c++) {
            if(i != c) {
              if(arry[i] == arry[c]) {
                  return true
              }
            }
          }
        }
        return false
    },
    validateNums(){
      console.log(this.isNoRepet(this.gamename), this.hasDuplicate())
      if(this.isNoRepet(this.pick)) {
        if(this.hasDuplicate()) {
          this.VA_ALERT('error', "Duplicate number found!")
          return
        }
      }
      
       if(this.pick=='P3') {
         if(this.num1 =="_" && this.num2 == "_" && this.num3 == "_") {
              this.VA_ALERT('error', "Complete number combination!")
              return 
          }
       } else if(this.pick=='P4') {
        if(this.num1 == "_" && this.num2 =="_" && this.num3 =="_" && this.num4 =="_") {
              this.VA_ALERT('error', "Complete number combination!")
              return
          }
       }  else if(this.pick=='P5') {
        if(this.num1 == "_" && this.num2 == "_" && this.num3 == "_" && this.num4 == "_" && this.num5 == "_") {
              this.VA_ALERT('error', "Complete number combination!")
              return
          }
       } else if(this.pick=='P6') {
        if(this.num1 == "_" && this.num2 == "_" && this.num3 == "_" && this.num4 == "_" && this.num5 == "_" && this.num6 !="_") {
              this.VA_ALERT('error', "Complete number combination!")
              return
          }
       } else {
         if(this.num1 == "_" && this.num2 == "_") {
              this.VA_ALERT('error', "Complete number combination!")
              return
          }
       }
      this.returnLine()
    },
    setNum(val) {
      if(this.num1 == '_') {
          this.num1 = val
      } else if(this.num2 == '_') {
         this.num2 = val
      } else if(this.num3 == '_') {
         this.num3 = val
      }  else if(this.num4 == '_') {
         this.num4 = val
      }  else if(this.num5 == '_') {
         this.num5 = val
      } else if(this.num6 == '_') {
         this.num6 = val
      } 
      if(this.pick =='P2-POMPYANG') {
        this.num2 = this.num1
      }
    }
  }
}
</script>
